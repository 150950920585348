import './win-check.scss';
import { AjaxLoader } from '../../utilities/js/ajax-load-promise.js';

class WinCheck {
    constructor ($element) {
        this.$winningCheck = $element;
        this.$form = this.$winningCheck.querySelector('[data-win-check="form"]');
        this.$resultContainer = this.$winningCheck.querySelector('[data-win-check="results"]');
        this.$ajaxURI = this.$form.getAttribute('data-action');
        this.$submitButton = this.$form.querySelector('[data-win-check="submit"]');
        this.$input = this.$form.querySelector('[data-win-check="input"]');
        this.$inputWrap = this.$form.querySelector('[data-win-check="inputwrap"]');
        this.$errorTimeout = null;

        this.ajaxActive = false;
    }

    initialize () {
        this.setEvents();
    }

    setEvents () {
        this.setInputFilter(this.$input, (value) => { return /^\d*$/.test(value); });

        this.$submitButton.addEventListener('click', (e) => {
            e.preventDefault();
            checkInput();
        });

        this.$form.addEventListener('submit', (e) => {
            e.preventDefault();
            clearTimeout(this.$errorTimeout);
            checkInput();
        });

        const checkInput = () => {
            if (this.$input.value !== '' && parseInt(this.$input.value) !== 0 && this.$input.value.length === 7) {
                if (this.ajaxActive === false) {
                    this.ajaxActive = true;
                    this.sendRequest();
                } else {
                    console.log('Please wait.....');
                }
            } else {
                this.$inputWrap.classList.add('has--error');
                this.$resultContainer.style.height = this.$resultContainer.scrollHeight + 'px';

                setTimeout(() => {
                    this.$resultContainer.style.height = '';
                }, 100);

                this.$errorTimeout = setTimeout(() => {
                    this.$inputWrap.classList.remove('has--error');
                    clearTimeout(this.$errorTimeout);
                }, 7000);
            }
        };
    }

    setInputFilter ($input, inputFilter) {
        let oldValue = null;
        let oldSelectionStart = null;
        let oldSelectionEnd = null;
        const selectionStart = null;
        const selectionEnd = null;

        ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach((event) => {
            $input.addEventListener(event, () => {
                if (inputFilter($input.value)) {
                    oldValue = $input.value;
                    oldSelectionStart = selectionStart;
                    oldSelectionEnd = selectionEnd;
                } else if (Object.prototype.hasOwnProperty.call($input, 'oldValue')) {
                    $input.value = oldValue;
                    $input.setSelectionRange(oldSelectionStart, oldSelectionEnd);
                } else {
                    $input.value = oldValue;
                }
            });
        });
    }

    sendRequest () {
        const result = AjaxLoader.getData(this.$form.getAttribute('data-action'), {
            method: 'POST',
            data: new FormData(this.$form)
        });

        this.setResult(result);
    }

    createElementFromHTML (htmlString) {
        const div = document.createElement('div');
        div.innerHTML = htmlString.trim();
        const itemsArray = [];

        if (div.children.length > 1) {
            Array.prototype.push.apply(itemsArray, div.children);
        } else {
            itemsArray.push(div.firstChild);
        }
        return itemsArray;
    }

    setResult (result) {
        result.then((results) => {
            results = JSON.parse(results.toString());
            this.ajaxActive = false;
            const $result = this.createElementFromHTML(results.html);
            this.$resultContainer.style.height = this.$resultContainer.scrollHeight + 'px';

            setTimeout(() => {
                this.$resultContainer.style.height = '';

                setTimeout(() => {
                    this.$resultContainer.innerText = '';
                    this.$resultContainer.appendChild($result[0]);

                    setTimeout(() => {
                        this.$resultContainer.style.height = this.$resultContainer.scrollHeight + 'px';

                        setTimeout(() => {
                            this.$resultContainer.style.height = 'auto';
                        }, 400);
                    }, 100);
                }, 350);
            }, 100);
        });
    }
}

export { WinCheck };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    const $winCheck = $context.querySelector('[data-win-check="root"]');

    if ($winCheck) {
        const winCheck = new WinCheck($winCheck);
        winCheck.initialize();
    }
});
