class AjaxLoader {
    static getData (uri, options) {
        return new Promise(function (resolve, reject) {
            let settings = {
                method: 'GET',
                data: null,
                isCorsAllowed: false
            };

            settings = Object.assign({}, settings, options);

            const xhr = new XMLHttpRequest();

            xhr.open(settings.method, uri, true);

            if (!settings.isCorsAllowed) {
                xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            }

            xhr.onload = () => {
                const result = xhr.responseText;

                if (xhr.status >= 200 && xhr.status < 400) {
                    resolve(result);
                } else {
                    reject(result);
                }
            };

            xhr.send(settings.data);
        });
    }
}

export { AjaxLoader };
